<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="10"
      cy="8.74463"
      rx="2.5"
      ry="2.5"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M17.5 8.02419C17.5 15.1526 10 18.75 10 18.75C10 18.75 2.5 15.1526 2.5 8.02419C2.5 4.28291 6.17649 1.25 10 1.25C13.8235 1.25 17.5 4.28291 17.5 8.02419Z"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>
